.p-checkbox {
  .p-checkbox-box {
    border-radius: 0px;
    box-shadow: $inputShadow;

    &.p-focus {
      outline-offset: $focusOutlineOffset;

      &.p-highlight {
        border-color: $checkboxActiveBorderColor;
      }
    }
  }

  &.p-checkbox-disabled {
    opacity: 1;

    .p-checkbox-box {
      background-color: $disabledInputBg;
      border: $inputBorder;

      .p-checkbox-icon {
        color: $textSecondaryColor;
      }
    }
  }
}
