$primaryColor: #2dc653 !default;
$primaryDarkColor: #10b981 !default;
$primaryDarkerColor: #047857 !default;
$primaryTextColor: #ffffff !default;
$primaryBlackTextColor: #484848 !default;
$lightBlackTextColor: #475467 !default;
$primaryLinearGradientColor: linear-gradient(
  183.68deg,
  #2dc653 3.21%,
  #004611 154.53%
) !default;

$highlightBg: #ecfdf5 !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: #d1fae5 !default;

@import '../_variables';
@import './_fonts';
@import '../../../theme-base/_components';
@import '../extensions/_extensions';
