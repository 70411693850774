/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@import 'styles/themes/light/green/theme.scss';

body {
  display: block;
  margin: 0;
  font-family: 'Lexend', sans-serif;
  color: var(--light-black-color);
}

h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 10px 0;
  color: var(--black-color);
}

h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
  color: var(--black-color);
}

h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 10px 0;
  color: var(--black-color);
}

h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  color: var(--black-color);
}

h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  color: var(--black-color);
}

h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  color: var(--black-color);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--border-color);
  margin: 1em 0;
  padding: 0;
}

%bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 3px 0;
}

.bullet-primary {
  @extend %bullet;
  background-color: var(--primary-color);
}

.bullet-info {
  @extend %bullet;
  background-color: var(--info-color);
}

.bullet-success {
  @extend %bullet;
  background-color: var(--success-color);
}

.bullet-warning {
  @extend %bullet;
  background-color: var(--warning-color);
}

.bullet-danger {
  @extend %bullet;
  background-color: var(--danger-color);
}

.notification-unread-badge {
  font-size: 8px;
  color: white;
  text-align: center;
  right: -13px;
  top: 14px;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid white;
  line-height: 21px;
  background-color: var(--notif-bubble-color);
}

.container {
  padding: 3px 1rem;
}

.content-container {
  padding: 3px 2rem;
}

// Extraordinary Font Sizes
.text-xss {
  font-size: 10px !important;
}

.readonly-field-text {
  color: var(--readonly-text-color) !important;
}

.text-color-dark {
  color: var(--black-color);
}

.text-color-secondary {
  color: var(--light-black-color);
}

.text-color-light {
  color: var(--lighter-black-color);
}

.text-color-primary {
  color: var(--primary-color);
}

.separator {
  border-bottom: 1.5px solid var(--border-color);
}

.form-error-message {
  color: var(--red-600);
}

.back-btn {
  width: 24px;
  height: 24px;
}

.cc-number {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 5px;
}

.button-plain {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: fit-content;
}

.button-link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: fit-content;
  color: #1877f2;
  text-decoration: underline;
}

.inline-link {
  color: var(--success-color);
  text-decoration-color: var(--success-color);
}

.inline-link-yellow {
  color: var(--warning-color);
  text-decoration-color: var(--warning-color);
}

.tag-primary {
  background-color: var(--primary-color);
  color: var(--surface-0);
}

.tag-success {
  background-color: var(--success-color);
  color: var(--surface-0);
}

.tag-info {
  background-color: var(--info-color);
  color: var(--surface-0);
}

.tag-xs {
  .p-tag-value {
    font-size: 8px;
  }
}

.color-black {
  color: var(--black-color);
}

.font-20px {
  font-size: 20px;
}

.h-20px {
  height: 20px;
}

.h-24px {
  height: 24px;
}

.h-50vh {
  height: 50vh;
}

.no-upcoming-trip {
  background-image: url('/assets/images/home-no-upcoming-trip.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ml-minus-20px {
  margin-left: -20px;
}

.mr-minus-20px {
  margin-right: -20px;
}

.prepade-table-header {
  th {
    background-color: var(--black-color);
    color: var(--white-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  th:first-child {
    border-top-left-radius: var(--border-radius);
  }
  th:last-child {
    border-top-right-radius: var(--border-radius);
  }
}

.prepade-table-row {
  td {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
.prepade-table-row:last-child {
  td {
    border: none !important;
  }

  td:first-child {
    border-bottom-left-radius: var(--border-radius);
  }
  td:last-child {
    border-bottom-right-radius: var(--border-radius);
  }
}

.form-subtitle {
  color: var(--readonly-text-color);
}

.itinerary-banner {
  background-color: #9bb6fb;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.prepade-chip {
  border-radius: 0 !important;
  background-color: #292929 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  color: var(--white-color);
}

.icon-google {
  content: url('/assets/icons/icon-google.svg');
  height: 24px;
  width: 24px;
  display: block;
}
.icon-facebook {
  content: url('/assets/icons/icon-facebook.svg');
  height: 24px;
  width: 24px;
  display: block;
}
.icon-apple {
  content: url('/assets/icons/icon-apple.svg');
  height: 24px;
  width: 24px;
  display: block;
}

.p-button:hover {
  .icon-google {
    content: url('/assets/icons/icon-google-white.svg');
    height: 24px;
    width: 24px;
    display: block;
  }
  .icon-facebook {
    content: url('/assets/icons/icon-facebook-white.svg');
    height: 24px;
    width: 24px;
    display: block;
  }
  .icon-apple {
    content: url('/assets/icons/icon-apple-white.svg');
    height: 24px;
    width: 24px;
    display: block;
  }
}

.alert-dialog {
  border-radius: var(--border-radius);
}

.flight-token-card {
  border: 1px solid #eaecf0;
  box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03);
  color: var(--white-color);
  // background-image: url('/assets/images/circle-overlap-bg.svg');
  background: linear-gradient(92deg, #2dc653 0.95%, #004611 98.32%);
}

@media screen and (min-width: 576px) {
  .sm\:text-xss {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  .content-container {
    padding: 3px 4rem;
  }

  .md\:text-xss {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 35px;
    font-weight: 500;
    line-height: 50px;
    margin: 10px 0;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    margin: 10px 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 10px 0;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }

  h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }

  .content-container {
    padding: 3px 4rem;
  }

  .lg\:text-xss {
    font-size: 10px !important;
  }

  .lg\:account-main-content {
    border: var(--border-15);
    padding: 17px;
    box-shadow: var(--box-shadow);
    -webkit-box-shadow: var(--box-shadow);
    -moz-box-shadow: var(--box-shadow);
  }
}

@media screen and (min-width: 1200px) {
  .xl\:text-xss {
    font-size: 10px !important;
  }

  .xl\:account-main-content {
    border: var(--border-15);
    padding: 30px;
  }
}

// Square Radio Button
.square-radio input[type='radio'],
.square-radio-img input[type='radio'] {
  display: none;
}

.square-radio input[type='radio'] + label {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: var(--border-10);
  text-align: center;
  color: var(--black-color);
  background-color: var(--white-color);
}

.square-radio-img input[type='radio'] + label {
  display: inline-flex;
  width: 105px;
  height: 105px;
  border: var(--border-10);
  border-radius: var(--border-radius);
  text-align: center;
  color: var(--black-color);
  background-color: var(--white-color);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.square-radio label span {
  display: inline-flex;
  height: 100%;
  align-items: center;
}

.square-radio-img label img {
  width: 32px;
  height: 32px;
}

.square-radio-img label span {
  font-size: 16px;
  font-weight: 500;
  color: var(--light-black-color);
  line-height: 32px;
  margin-top: 5px;
}

.square-radio input[type='radio']:checked + label {
  color: var(--primary-color-text);
  background-color: var(--primary-color);
}

.square-radio-img input[type='radio']:checked + label {
  color: var(--black-color);
  border: var(--square-radio-checked-border);
  box-shadow: var(--square-radio-checked-box-shadow);
}

// Suqare (checkmark) radio button
.field-radiobutton {
  margin-bottom: 0.5rem !important;
}

.field-radiobutton input[type='radio'] {
  display: none;
}

.field-radiobutton label {
  position: relative;
  line-height: 22px !important;
  margin-left: 0 !important;
}

.field-radiobutton label::before,
.field-radiobutton label::after {
  content: '';
}

.field-radiobutton label::before {
  border: 1px solid var(--surface-300);
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.field-radiobutton label::before:hover {
  border-color: var(--surface-400);
}

.field-radiobutton label::after {
  background: var(--checkbox-bg-color);
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 1px;
  left: 1px;
  transition-duration: 0.2s;
  opacity: 0;
  cursor: pointer;
}

.field-radiobutton input[type='radio']:checked + label::before {
  border: none;
  width: 1.38rem;
  height: 1.38rem;
}

.field-radiobutton input[type='radio']:checked + label::after {
  opacity: 1;
  font-family: primeicons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: '\e909';
  font-size: 0.875rem;
  color: var(--white-color);
  text-align: center;
  line-height: 20px;
}

// PrimeNG
.p-hidden-accessible {
  display: none;
}

.p-dialog {
  max-width: max-content !important;
}

.p-rating-item {
  span {
    display: none;
  }
}

.p-rating .p-rating-item .p-rating-icon {
  color: #1c1b1f66;
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: #fdb913;
}

.p-accordion-header-text {
  color: var(--black-color);
}

.event-item-expand-btn.p-ripple.p-element.p-button.p-component.p-button-link.p-button-success.p-button-text {
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  color: var(--success-color);
}

// PrimeFlex
.text-contrast {
  color: var(--contrast-color);
}

.field > label {
  color: var(--contrast-color);
}

.h-38rem {
  height: 38rem !important;
}
.h-45rem {
  height: 45rem !important;
}
.m-h-45rem {
  max-height: 45rem !important;
}

// 2FA
.twofa-setup-alternative {
  input {
    width: 100%;
  }
}
