@use 'sass:math';

.p-tag {
  background: var(--primary-color);
  color: var(--primary-700);

  &.p-tag-success {
    background: var(--success-color);
  }

  &.p-tag-info {
    background: var(--info-color);
  }

  &.p-tag-warning {
    background: var(--orange-100);
  }

  &.p-tag-danger {
    background: var(--danger-color);
  }
}
