.p-avatar {
    background-color: $avatarBg;
    border-radius: $borderRadius;

    &.p-avatar-lg {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;

        .p-avatar-icon {
            font-size: 1.5rem;
        }
    }

    &.p-avatar-xl {
        width: 4rem;
        height: 4rem;
        font-size: 2rem;

        .p-avatar-icon {
            font-size: 2rem;
        }
    }
}

.p-avatar-group {
    .p-avatar {
        border: 2px solid $panelContentBg;
    }
}

.avatar-border-white {
    border: 4px solid #ffffff;
}

.avatar-xxl {
    width: 98px !important;
    height: 98px !important;
}

@media screen and (min-width: 576px) {
    .avatar-xxl {
        width: 98px !important;
        height: 98px !important;
    }
}

@media screen and (min-width: 768px) {
    .avatar-xxl {
        width: 98px !important;
        height: 98px !important;
    }
}

@media screen and (min-width: 992px) {
    .avatar-xxl {
        width: 10rem !important;
        height: 10rem !important;
    }
}