@mixin focused-button-ring($ring-color) {
  outline-color: $ring-color;
}

.p-button-label {
  font-weight: 500;
}

.p-button {
  &.p-button-success:enabled:focus-visible {
    @include focused-button-ring($successButtonBg);
  }

  &.p-button-info:enabled:focus-visible {
    @include focused-button-ring($infoButtonBg);
  }

  &.p-button-warning:enabled:focus-visible {
    @include focused-button-ring($warningButtonBg);
  }

  &.p-button-help:enabled:focus-visible {
    @include focused-button-ring($helpButtonBg);
  }

  &.p-button-danger:enabled:focus-visible {
    @include focused-button-ring($dangerButtonBg);
  }

  &.p-button-contrast:enabled:focus-visible {
    @include focused-button-ring($contrastButtonBg);
  }

  &.p-button-outlined {
    border-color: var(--primary-200);

    &:not(:disabled):hover,
    &:not(:disabled):active {
      border-color: var(--primary-200);
    }

    &.p-button-secondary {
      border-color: var(--surface-200);
      color: $textSecondaryColor;

      &:not(:disabled):hover {
        background: $buttonHoverBg;
        color: $buttonTextHoverColor;
        border-color: $buttonHoverBorderColor;
      }

      &:not(:disabled):active {
        color: $textSecondaryColor;
        background-color: $shade200;
        border-color: var(--surface-200);
      }
    }

    &.p-button-success {
      border-color: #bbf7d0;

      &:not(:disabled):hover,
      &:not(:disabled):active {
        border-color: #bbf7d0;
      }
    }

    &.p-button-info {
      border-color: #bae6fd;

      &:not(:disabled):hover,
      &:not(:disabled):active {
        border-color: #bae6fd;
      }
    }

    &.p-button-warning {
      border-color: #fed7aa;

      &:not(:disabled):hover,
      &:not(:disabled):active {
        border-color: #fed7aa;
      }
    }

    &.p-button-help {
      border-color: #e9d5ff;

      &:not(:disabled):hover,
      &:not(:disabled):active {
        border-color: #e9d5ff;
      }
    }

    &.p-button-danger {
      border-color: #fecaca;

      &:not(:disabled):hover,
      &:not(:disabled):active {
        border-color: #fecaca;
      }
    }

    &.p-button-contrast {
      border-color: #334155;

      &:not(:disabled):hover,
      &:not(:disabled):active {
        border-color: #334155;
      }
    }
  }

  &.p-button-secondary {
    &.p-button-text {
      color: $textSecondaryColor;

      &:not(:disabled):hover {
        background: $shade100;
        color: $textSecondaryColor;
      }

      &:not(:disabled):active {
        background: $shade200;
        color: $textSecondaryColor;
      }
    }
  }
}
